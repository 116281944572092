(function() {
	
	var app = angular.module('AuthModule', []);
	
	app.factory('authService', ['$http', "$rootScope", function($http, $rootScope) {
		var currentUser = {};
		var isLoggedIn = "init";
		
		$http.get("/json/users/currentuser").then(function(result) {
			if(result.data.user) {
				currentUser = result.data.user;
				isLoggedIn = true;
				notify();
			} else {
				isLoggedIn = false;
			}
    	}, function(error) {
    		isLoggedIn = false;
    	})
    	
    	
    	var notify = function() {
			$rootScope.$emit('notifying-action-current-user-event');
		};
		
		return {
			login: function(data, callback) {
				$http.post("/json/auth", data).then(function(result) {
					currentUser = result.data.user;
					isLoggedIn = true;
		    		callback(result.data);
		    	}, function(error) {
		    		console.log(error);
		    	})
		    	
			},
			logout: function() {
				$http.get("/json/users/logout").then(function(result) {
					$.removeCookie("email", {path: "/"});
					$.removeCookie("accessToken", {path: "/"});
					
					if(currentUser.isGoogleUser) {
						window.location.href = currentUser.logoutURL;
						return;
					}
					isLoggedIn = false;
					currentUser = undefined;
					window.location.href = "/login";
				}, function(error) {
		    		console.log(error);
		    	})
			},
			isLoggedIn: function() {
		    	return isLoggedIn;
			},
			currentUser: function() {
				return currentUser;
			},
			subscripe: function(scope, callback) {
				var handler = $rootScope.$on('notifying-action-current-user-event', callback);
	            scope.$on('$destroy', handler);
			}
		}
		
	}])
	
	
})();